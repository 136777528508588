<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="display:flex"
      >
        <logo class="d-none d-lg-block" />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('Welcome_to') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login_page_description') }}
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
            >
              <!-- pin -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('code') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('code')"
                  vid="code"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="code"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="login-password"
                      :placeholder="$t('code')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-card-text class="text-center mt-2">
                <div class="countdown">
                  <flip-countdown
                    :deadline="deadline"
                    :show-days="false"
                    :show-hours="false"
                    @timeElapsed="timeElapsedHandler"
                  />
                </div>
                <b-button
                  v-if="!pendingResend"
                  type="submit"
                  variant="outline-primary"
                  block
                  :disabled="disable"
                  class="resend"
                  @click.stop.prevent="resend"
                >
                  {{ $t('resend') }}
                </b-button>
                <b-button
                  v-if="pendingResend"
                  variant="outline-primary"
                  block
                  disabled
                  class="resend"
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                </b-button>
              </b-card-text>
              <!-- submit buttons -->
              <b-button
                v-if="!pending"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                @click.stop.prevent="submit"
              >
                {{ $t('sign_in') }}
              </b-button>
              <b-button
                v-if="pending"
                variant="primary"
                block
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import FlipCountdown from 'vue2-flip-countdown'
import store from '@/store'
import AuthApis from '@/services/apis/auth'
import Logo from '../components/logo.vue'

const auth = new AuthApis()
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    FlipCountdown,
    Logo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      code: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
      pending: false,
      pendingResend: false,
      disable: true,
      deadline: this.getExpireTime(),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (!localStorage.getItem('email')) {
      this.$router.push(`/${this.$i18n.locale}/login`)
    }
  },
  methods: {
    ...mapActions(['loginSection2']),
    async submit() {
      const success = await this.$refs.loginForm.validate()
      if (success) {
        this.pending = true
        const data = { code: this.code, email: localStorage.getItem('email') }
        this.loginSection2(data).then(res => {
          this.$swal({
            title: `${this.$t('login_message')}`,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.$router.push(`${this.$route.query.redirect ? `${this.$route.query.redirect}` : `/${res.data.results.user.language}/profile`}`)
        }).finally(() => {
          this.pending = false
        })
      }
    },

    timeElapsedHandler() {
      this.disable = false
    },

    async resend() {
      this.pendingResend = true
      const data = { email: localStorage.getItem('email') }
      const response = await auth.resendCode(data).finally(() => {
        this.pendingResend = false
      })
      this.$swal({
        title: response?.data?.message,
        icon: 'success',
        timer: 2500,
        showConfirmButton: false,
      })
      if (response.status === 200) {
        this.disable = true
        this.deadline = this.getExpireTime()
      }
    },

    onVerify(response) {
      this.form.recaptcha = response
    },

    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },

    getExpireTime() {
      const expireTime = localStorage.getItem('expireTime')
      const currentDate = new Date()
      const msSinceEpoch = currentDate.getTime()
      const seventeenHoursLater = new Date(msSinceEpoch + expireTime * 60 * 1000)
      const currentDayOfMonth = seventeenHoursLater.getDate()
      const currentMonth = seventeenHoursLater.getMonth()
      const currentYear = seventeenHoursLater.getFullYear()
      const hours = seventeenHoursLater.getHours()
      const minutes = seventeenHoursLater.getMinutes()
      const seconds = seventeenHoursLater.getSeconds()
      const dateString = `${currentYear}-${currentMonth + 1}-${currentDayOfMonth} ${hours}:${minutes}:${seconds}`
      return dateString
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    .auth-login-form
    {
     .card-text
     {
       position: relative;
       width: 100%;
       display: inline-block;
     }
      .btn-outline-primary
      {
        width: 100%;
        margin: 20px 0 0;
      }
    }

</style>
